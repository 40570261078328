<template>
	<page class="page--home"></page>
</template>

<script>
import Page from './Page.vue';

export default {
	name: 'HomePage',
	components: {
		Page
	}
};
</script>

<style lang="less" scoped>
.page--home {
	display: none;
}
</style>
